<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <base-login-material-wizard
        ref="baseLoginMat"
        v-model="tab"
        :available-steps="[1]"
        :items="tabs"
        :disabledBtnLogar="disabledBtnLogar"
        :loading="loading"
        class="mx-auto"
        @click:next="next(valid)"
        btnLogarLabel="Redefinir Senha"
      >
        <v-tab-item class="pb-1"></v-tab-item>
          <v-tab-item class="pb-1">
            <form>
              <v-row class="mx-auto" justify="space-around">
              
              <small>Defina sua nova senha</small>

              <v-col cols="12" md="11">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    :type="mostrarSenha ? 'text' : 'password'"
                    color="primary"
                    label="Senha*"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                    @keyup.enter="next(valid)"
                    @click:append="mostrarSenha = !mostrarSenha"
                    validate-on-blur
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password_confirmation"
                >
                  <v-text-field
                    v-model="password_confirmation"
                    :error-messages="errors"
                    :type="mostrarSenha ? 'text' : 'password'"
                    color="primary"
                    label="Confirmar Senha*"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                    @keyup.enter="next(valid)"
                    @click:append="mostrarSenha = !mostrarSenha"
                    validate-on-blur
                  />
                </validation-provider>
              </v-col>

            </v-row>
          </form>
        </v-tab-item>
      </base-login-material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import authService from '@/services/AuthService';

export default {
  name: 'LoginWizard',

  components: {
    BaseLoginMaterialWizard: () =>
      import('@/components/login/base/BaseLoginMaterialWizard.vue'),
  },

  data() {
    return {
      tab: 1,
      tabs: ['Redefinir senha'],
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: '',
      password_confirmation: '',
      mostrarSenha: false,
      loading: false,
    };
  },

  computed: {
    disabledBtnLogar() {
      return  !this.password || !this.password_confirmation || this.password !== this.password_confirmation;
    }
  },

  methods: {
    next() {
      this.loading = true;

      authService.resetPassword({ 
        email: this.email,
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
      .then(() => {
        this.$router.push('/login');

        this.$toast.success('Nova senha definida com sucesso!', '', {
            position: 'topRight',
            timeout: 5000
          });
      })
      .catch((error) => {
        let message = error?.data?.message || 'Erro ao redefinir senha';

        if (error.status === 422) {
          const data = error.data;
          message = error.data.message;

          if (data.errors.password?.length) message = data.errors.password[0];
        }
        
          this.$toast.error(message, '', {
            position: 'topRight',
            timeout: 5000
          });
        })
      .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>